<template>
    <div class="register-banner">
        <div class="text-container">
            <div class="text-content">
                <p class="R1">{{ texts.RegisterBanner.R1 }}</p>
                <p class="R2">{{ texts.RegisterBanner.R2 }}</p>
                <p class="R3">{{ texts.RegisterBanner.R3 }}</p>
                <v-btn class="RB" @click="redirectToLogin">
                    {{ texts.RegisterBanner.BT }}
                </v-btn>
            </div>
        </div>

        <div class="image-container">
            <div class="image-content">
                <img src="@/assets/images/Trex_Dashboard.jpg" alt="Dashboard" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RegisterBanner",
    props: {
        texts: {
            type: Object,
            required: true,
        },
    },
    methods: {
        redirectToLogin() {
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>
.register-banner {
    display: flex;
    justify-content: space-around;
    background-color: #121a39;
    position: relative;
    min-height: 60vh;
    overflow: hidden;
}

.text-container {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 10%;
}

.image-container {
    flex-basis: 50%;
    display: flex;
    padding-top: 5%;
    position: relative; 
    justify-content: flex-end;
    align-items: flex-end;
}

.text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.R1 {
    font-size: 1vw;
    color: #61a3d9;
}

.R2 {
    font-size: 2.1vw;
    color: white;
}

.R3 {
    font-size: 1vw;
    color: #bfd7ed;
}

.RB {
    background-color: #0075b8 !important;
    color: white !important;
    border-radius: 5px;
    padding: 10px 25px;
    width: 15vw;
    height: 5vh;
    font-size: 13px !important;
}

.image-content {
    display: flex;
    justify-content: flex-end;
}

.image-content img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    object-position: bottom right; 
}

@media (max-width: 768px) {
    .register-banner {
        flex-direction: column;
        min-height: auto;
    }

    .text-container, .image-container {
        flex-basis: 100%;
        justify-content: center;
    }

    .text-content {
        padding-left: 0;
        text-align: center;
    }

    .image-content img {
        max-width: 70vw;
        max-height: 70vh;
    }
}
</style>